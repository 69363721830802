import './editor.css';
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import {LexicalErrorBoundary} from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin';
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {ListPlugin} from '@lexical/react/LexicalListPlugin';
import {MarkdownShortcutPlugin} from '@lexical/react/LexicalMarkdownShortcutPlugin';
import {ListNode, ListItemNode} from '@lexical/list';
import { CodeNode } from '@lexical/code';
import { LinkNode } from '@lexical/link';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';

import {HorizontalRulePlugin} from '@lexical/react/LexicalHorizontalRulePlugin';

import ToolbarPlugin from './ToolbarPlugin';
import { Box } from '@mui/material';
import colors from '../../../colors';


const CabRTEditor = ({ placeholder, onChange, editorState }: {
  placeholder: string;
  onChange: (s: string) => void;
  editorState: string;
}) => {
  return (
    <LexicalComposer initialConfig={{...editorConfig, editorState: editorState }}>
      <Box sx={{
        borderWidth: 1, borderColor: colors.black500, borderStyle: 'solid',
        borderTopLeftRadius: 4, borderTopRightRadius: 4
      }}>
        <ToolbarPlugin />
        <ListPlugin />
        {/* <DragDropPaste /> */}
        {/* <ClearEditorPlugin /> */}
        {/* <ComponentPickerPlugin /> */}
        {/* <EmojiPickerPlugin /> */}
        {/* <AutoEmbedPlugin /> */}
        {/* <MentionsPlugin /> */}
        {/* <EmojisPlugin /> */}
        {/* <HashtagPlugin /> */}
        {/* <KeywordsPlugin /> */}
        {/* <SpeechToTextPlugin /> */}
        {/* <AutoLinkPlugin /> */}
        <Box sx={{ background: '#fff', position: 'relative' }}>
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                className="editor-input"
                aria-placeholder={placeholder}
                placeholder={
                  <div className="editor-placeholder">{placeholder}</div>
                }
                list=""
              />
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <MarkdownShortcutPlugin />
          {/* <CodeHighlightPlugin /> */}
          {/* <CheckListPlugin /> */}
          {/* <ListMaxIndentLevelPlugin maxDepth={7} /> */}
          {/* <TablePlugin
            hasCellMerge={tableCellMerge}
            hasCellBackgroundColor={tableCellBackgroundColor}
          /> */}
          {/* <TableCellResizer /> */}
          {/* <TableHoverActionsPlugin /> */}
          {/* <ImagesPlugin /> */}
          {/* <InlineImagePlugin /> */}
          {/* <LinkPlugin /> */}
          {/* <FigmaPlugin /> */}
          {/* <ClickableLinkPlugin disabled={isEditable} /> */}
          <HorizontalRulePlugin />
          {/* <EquationsPlugin /> */}
          {/* <TabFocusPlugin /> */}
          {/* <TabIndentationPlugin /> */}
          {/* <CollapsiblePlugin /> */}
          {/* <PageBreakPlugin /> */}
          {/* <LayoutPlugin /> */}

          <OnChangePlugin onChange={state => {
            state.read(() => {
              onChange(JSON.stringify(state.toJSON()));
            });
          }} />
          <HistoryPlugin />
          <AutoFocusPlugin />
        </Box>
      </Box>
    </LexicalComposer>
  );
};

export default CabRTEditor;



const theme = {
  code: 'editor-code',
  heading: {
    h1: 'editor-heading-h1',
    h2: 'editor-heading-h2',
    h3: 'editor-heading-h3',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  image: 'editor-image',
  link: 'editor-link',
  list: {
    listitem: 'editor-listitem',
    nested: {
      listitem: 'editor-nested-listitem',
    },
    ol: 'editor-list-ol',
    ul: 'editor-list-ul',
  },
  ltr: 'ltr',
  paragraph: 'editor-paragraph',
  placeholder: 'editor-placeholder',
  quote: 'editor-quote',
  rtl: 'rtl',
  text: {
    bold: 'editor-text-bold',
    code: 'editor-text-code',
    hashtag: 'editor-text-hashtag',
    italic: 'editor-text-italic',
    overflowed: 'editor-text-overflowed',
    strikethrough: 'editor-text-strikethrough',
    underline: 'editor-text-underline',
    underlineStrikethrough: 'editor-text-underlineStrikethrough',
  },
};

const editorConfig = {
  namespace: 'CabRTEditor',
  nodes: [
    ListNode,
    ListItemNode,
    HorizontalRuleNode,
    CodeNode,
    LinkNode,
    HeadingNode,
    QuoteNode,
  ],
  // Handling of errors during update
  onError(error: Error) {
    throw error;
  },
  // The editor theme
  theme,
};

